<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx" style="width: 100%; margin-top: 25px;">
    <img fxFlex="30" class="logo" [src]="'assets/images/e-filing.jpg'" />
</div>
<nb-accordion>
    <nb-accordion-item>
        <nb-accordion-item-header>E-FILING TUTORIAL VIDEO</nb-accordion-item-header>
        <nb-accordion-item-body>
            <div style="position: relative; overflow: hidden; padding-top: 56.25%;"><iframe src="https://share.synthesia.io/embeds/videos/20742267-9458-4a9f-b0bf-dd060762f439" loading="lazy" title="Synthesia video player - ClerkNet Civil E-Filing" allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe></div>
        </nb-accordion-item-body>
    </nb-accordion-item>

    <nb-accordion-item>
     <nb-accordion-item-header>FILE/DOCUMENT FORMAT</nb-accordion-item-header>
     <nb-accordion-item-body>

        Please ensure that the document(s) you submit with your e-filing are in PDF format, which is the standard for e-filing in most jurisdictions, including the federal courts. 
        
        <ul style="text-transform: uppercase;">
            <li>
                FILES MUST BE UPLOADED AS AN ADOBE PDF (.PDF) FILE FORMAT - Please note that we cannot accept image files or Word document .doc/.docx files.
            </li>
            <li>
                PDF file must be unlocked and not password-protected, as the system will not be able to process password-protected files
            </li>
            <li>
                FILE UPLOADS SHOULD BE IN BLACK AND WHITE AND LEGAL PAGE SIZE WITH A 2" MARGIN ON ALL SIDES!
            </li>
            <li>
                FILE CONTENT MUST BE CLEARLY VISIBLE AND NOT BLURRED - CLERKS MAY REJECT E-FILINGS IF THE CONTENT IS NOT CLEARLY VISIBLE
            </li>
            <li>
                SEALED DOCUMENTS MAY NOT BE E-FILED.
            </li>
            <li>
                IF E-EFILING A WILL OR PROMISSORY NOTE, YOU MUST SEND THE ORIGINAL TO THE CLERKS OFFICE
            </li>
            <li>
                CLERKS MAY REJECT E-FILINGS IF ANY OF THE ABOVE REQUIREMENTS ARE NOT SATISFIED!
            </li>
        </ul>
     </nb-accordion-item-body>
    </nb-accordion-item>

    <nb-accordion-item>
        <nb-accordion-item-header>E-FILING PROCESSING HOURS</nb-accordion-item-header>
        <nb-accordion-item-body>
            <li>
                E-FILINGS ARE DATE/TIME STAMPED AT THE TIME THEY ARE SUBMITTED VIA THE CLERKNET E-FILING PORTAL
           </li>
           <li>
             Although ClerkNet and the Clerk of Court strive to have the ClerkNet E-Filing portal on-line and available 24 hours per day, 7 days per week, there will be times when ClerkNet may become unavailable. Neither the Clerk Of Court or ClerkNet will be a party to any dispute arising from an attorney’s failure to make a timely filing.
            </li>
        </nb-accordion-item-body>
       </nb-accordion-item>

       <nb-accordion-item>
        <nb-accordion-item-header>E-FILING FEES/PAYMENTS</nb-accordion-item-header>
        <nb-accordion-item-body>
            <ul>
                <li>
                    E-FILING ACCOUNTS MUST HAVE A VALID PAYMENT METHOD - CREDIT CARD OR ACH BANK ACCOUNT - LINKED TO THEIR
                    ACCOUNT PRIOR TO SUBMITTING AN E-FILING. 
                </li>
                
                <li>
                    E-FILINGS SUBMITTED VIA CLERKNET WILL BE ASSESSED A $5.00 ONLINE E-FILING SERVICE FEE PER E-FILING TRANSACTION CHARGED DIRECTLY TO YOUR
                    PAYMENT METHOD ON FILE IN ADDITION TO STANDARD CREDIT CARD/ACH PROCESSING FEES INDICATED IN THE TERMS
                </li>

                <li>
                    BY SUBMITTING AN E-FILING USER IS AUTHORIZING THE CLERK TO CHARGE THEIR SELCTED E-FILING PAYMENT METHOD FOR THE AUTHORIZED AMOUNT. E-FILING FINANCIAL ACCOUNTS WILL BE CHARGED ONCE THE E-FILING HAS BEEN APPROVED AND ACCEPTED BY THE CLERK.
                </li>

                <li style="font-weight: bold; color: red;">
                    *** IF YOUR PRIMARY PAYMENT METHOD FAILS TO PROCESS FOR ANY REASON, THE E-FILING WILL BE REJECTED DUE TO A
                    PAYMENT FAILURE. IT IS THE E-FILER'S RESPONSIBILITY TO ENSURE YOUR PAYMENT METHOD IS VALID AND SUFFICIENT FUNDS ARE APPROVED.
                </li>

                <li style="font-weight: bold; color: red;">
                    *** IF YOUR PARTY/LITIGANT SUIT BALANCE IS INSUFFICIENT TO PROCESS THE E-FILING, YOU WILL NEED TO DEPOSIT FUNDS TO THE CASE OTHERWISE THE E-FILING WILL BE REJECTED DUE TO INSUFFICIENT FUNDS ON THE SUIT.
                </li>

                <li style="font-weight: bold; color: red;">
                    *** NO REFUNDS WILL BE ISSUED FOR E-FILINGS, PROCESSING FEES AND/OR PAYMENT PROCESSING FEES ***
                </li>
            </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>

       <nb-accordion-item>
        <nb-accordion-item-header>NEW CIVIL CASE ADVANCE DEPOSIT REQUIREMENTS </nb-accordion-item-header>
        <nb-accordion-item-body>
            <span>All fees herein are established in accordance with La. R.S. 13:841 (as amended by Act No. 243 of the 2006 Regular Legislative Session) and other relevant statutes.</span>
          <ul>
              <li style="color: red;">
                   E-FILING NEW CIVIL SUITS REQUIRES AN ADVANCE DEPOSIT AMOUNT TO BE SUBMITTED WITH THE NEW SUIT
               </li>
               <li style="color: red;">
                THIS AMOUNT DEPENDS ON THE SPECIFIC CASE TYPE AND PROCEEDING. YOU ARE RESPONSIBLE FOR ENSURING YOUR ADVANCE DEPOSIT AMOUNT IS SUFFICIENT TO COVER ALL INITIAL SUIT FEES AS REQUIRED BY THE CLERK.
            </li>
            <li style="color: red;">
                ADDITIONAL FUNDS CAN BE DEPOSITED TO YOUR PARTY/LITIGANTS SUIT BALANCE AS NEEDED
            </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>

       <nb-accordion-item>
        <nb-accordion-item-header>PAYMENT PROCESSING FEES</nb-accordion-item-header>
        <nb-accordion-item-body>
          <ul>
     
              <li>
                   US BANK ACCOUNT / ACH E-CHECK PAYMENTS WILL BE ASSESSED A SERVICE FEE OF 0.8% CAPPED AT $5.00 <br>
                   EXAMPLE => $300 E-FILING DEPOSIT WILL CHARGE YOUR CREDIT CARD $300.00 + $5.00 E-FILING ONLINE FEE + $2.46 ACH PROCESSING FEE => $307.46 TOTAL
              </li>
              <li>
                CREDIT CARD PAYMENTS WILL BE ASSESSED A SERVICE FEE OF 2.9% + $0.30 PER TRANSACTION<br>
                EXAMPLE => $300 E-FILING DEPOSIT WILL CHARGE YOUR CREDIT CARD $300.00 + $5.00 E-FILING ONLINE FEE + $9.42 CREDIT CARD PROCESSING FEE => $314.42 TOTAL
               </li>
               <li>
                E-FILING USERS ARE RESPONSIBLE FOR ALL PAYMENT PROCESSING FEES IN ADDITION TO ADVANCE DEPOSIT FUNDS<br>
               </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>
       

       <nb-accordion-item>
        <nb-accordion-item-header>EMAIL NOTIFICATIONS</nb-accordion-item-header>
        <nb-accordion-item-body>
          <ul>
              <li>
                   Users will receive email notifictions related to the status of each E-FILING. Emails are sent to the email address of the user's account.
              </li>
              <li>
                   EMAILS WILL BE SENT FROM EMAIL ACCOUNT noreply&#64;clerknet.app => PLEASE MAKE SURE YOUR EMAIL IS CONFIGURED TO ACCEPT EMAILS FROM THIS ADDRESS!
               </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>
   </nb-accordion>

